<template>
    <div class="list-bottom">
        <!-- 注意！！！ 只有分页的时候加: page-right -->
        <div class="list-bottom-column flex align-items" :class="{'page-right': showPageClass}">
            <div class="list-bottom-left">
                <a-checkbox
                    :indeterminate="indeterminate"
                    :checked="checkAll"
                    :disabled="data && data.length === 0"
                    @change="onCheckAllChange"
                    v-if="condition && condition.checkall === true"
                >
                    全选
                </a-checkbox>

                <!--报名管理-->
                <template v-if="scenario === 'enterList'">
                    <a-button type="primary" @click="optionFun('chushen')">批量初审</a-button>
                    <a-button type="primary" @click="optionFun('csclose')">初审不通过</a-button>
                </template>

                <!--岗位管理-->
                <template v-if="scenario === 'jobList'">
                    <a-button type="primary" @click="optionFun('delay')">批量延期</a-button>
                </template>

                <!--项目管理-->
                <template v-if="scenario === 'projectList'">
                    <a-button type="primary" @click="optionFun('close')">批量关闭</a-button>
                </template>
            </div>
            <div class="page-number">
                <a-pagination
                    :current="parseInt(page)"
                    :pageSize="parseInt(pagesize)"
                    :total="parseInt(total)"
                    :show-total="(total, range) => `共${total}条数据`"
                    @change="pageTo"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: [String, Number],
            default: 0
        },
        pagesize: {
            type: [String, Number],
            default: 0,
        },
        total: {
            type: [String, Number],
            default: 0,
        },
        data: {
            type: [Object, Array],
            default: null,
        },
        selectedRowKeys: {
            type: [Object, Array],
            default: null,
        },
        condition: {
            type: [Object],
            default: null,
        },
        scenario: {
            type: [String],
            default: '',
        },
    },
    watch: {
        selectedRowKeys(val) {
            let len = val.length;
            if (this.data) {
                if (len > 0 && this.data.length === len) {
                    this.checkAll = true;
                    this.indeterminate = false;
                } else {
                    this.checkAll = false;
                    this.indeterminate = len == 0 ? false : true;
                }
            }
        },
    },
    data() {
        return {
            checkAll: false,// 全选
            indeterminate: false,// 部分选中
        };
    },
    computed: {
        showPageClass() {

            //if(this.condition && this.condition.checkall && this.inArray(this.scenario, scenarioOptionArr) > -1) {
            return false;
            //} else {
            //    return true;
            //}
        }
    },
    methods: {
        // 全选or全反选
        onCheckAllChange(e) {
            this.indeterminate = false;
            if (e.target.checked) {
                this.checkAll = true;
            } else {
                this.checkAll = false;
            }
            this.$emit('checked', this.checkAll);
        },
        // 翻页
        pageTo(e) {
            this.$emit('page', e);
        },
        // 操作
        optionFun(op) {
            this.$emit('optionFun', {scenario: this.scenario, op: op});
        },
    }
}
</script>

<style lang="less">
@import '../../assets/css/init/var.less';

.list-bottom {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    margin-left: 24px;

    .list-bottom-column {
        padding: 0 24px;
        height: 62px;
        justify-content: space-between;
        border-left: 1px solid @border-color;
        border-right: 1px solid @border-color;
        border-top: 1px solid @border-color;
    }

    .page-right {
        justify-content: flex-end;
    }

    .list-bottom-left {
        button {
            margin-left: 16px;
        }
    }
}
</style>
