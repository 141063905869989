<template>
    <div class="wrap">
        <listHeader :title="postData.id > 0 ? '编辑新闻' : '发布新闻'"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-column-title flex align-items"><span class="line"></span>
                    <h3>新闻</h3></div>
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="20">
                            <a-form :form="form">

                                <a-form-item
                                    label="新闻分类"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-radio-group
                                        v-decorator="['ncid', {rules: [{ required: true, message: '请选择新闻分类' }], initialValue: ''}]">
                                        <a-radio v-for="(item, key) in config.ncid" :key="item.ncid" :value="item.ncid">
                                            {{ item.ncname }}
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-item>

                                <a-form-item
                                    label="标题"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入新闻标题"
                                             v-decorator="['title', {rules: [{ required: true, max: 64, message: '请输入新闻标题' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="封面图片"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-upload
                                        name="avatar"
                                        list-type="picture-card"
                                        class="avatar-uploader"
                                        :show-upload-list="false"
                                        :customRequest="uploadImg"
                                    >
                                        <img v-if="visible" :src="data.imageUrl" alt="avatar"/>
                                        <div v-else>
                                            <a-icon :type="loading ? 'loading' : 'plus'"/>
                                            <div class="ant-upload-text">
                                                选择图片
                                            </div>
                                        </div>
                                    </a-upload>
                                </a-form-item>

                                <a-form-item
                                    label="描述"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-textarea
                                        placeholder="请输入新闻描述"
                                        v-decorator="['description', {rules: [{ max: 128, message: '新闻描述不能超过128个字符' }], initialValue: ''}]"
                                        :auto-size="{ minRows: 2 }"
                                    />
                                </a-form-item>

                                <a-form-item
                                    label="发布日期"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-date-picker style="width: 100%" placeholder="不填默认为今天" v-decorator="['addtime',{rules: [{ required: false, message: '请选择日期' }]} ]"/>
                                </a-form-item>

                                <a-form-item
                                    label="内容"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <div style="border: 1px solid #ccc;">
                                        <Toolbar
                                            style="border-bottom: 1px solid #ccc"
                                            :editor="editor"
                                            :defaultConfig="toolbarConfig"
                                            :mode="mode"
                                        />
                                        <Editor
                                            style="height: 300px; overflow-y: hidden;"
                                            v-model="html"
                                            :defaultConfig="editorConfig"
                                            :mode="mode"
                                            @onCreated="onCreated"
                                        />
                                    </div>
                                </a-form-item>

                                <a-form-item
                                    label="转载地址"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入转载地址"
                                             v-decorator="['fromurl', {rules: [{ max: 128, message: '转载地址不能超过128个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    v-bind="formBtnLayout"
                                >
                                    <a-button type="primary" @click="create">确定发布</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader'
import {Boot} from '@wangeditor/editor'
import attachmentModule from '@wangeditor/plugin-upload-attachment'
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import moment from "moment";

Boot.registerModule(attachmentModule)

export default {
    components: {
        listHeader,
        Editor, Toolbar
    },
    data() {
        return {
            visible: false, // 是否显示图片src
            loading: false, // 显示图片上传中
            formItemLayout: {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 20,
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                'newsid': 0,
            },
            data: {},
            config: {},
            dateFormat: 'YYYY-MM-DD',
            imageList: [],

            editor: null,
            html: '',
            toolbarConfig: {
                insertKeys: {
                    index: 23, // 自定义插入的位置
                    keys: ['uploadAttachment'], // “上传附件”菜单
                },
            },
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        customUpload: this.uploadImage
                    },
                    uploadVideo: {
                        customUpload: this.uploadVideo
                    },
                    uploadAttachment: {
                        customUpload: this.uploadFile
                    },
                }
            },
            mode: 'default', // or 'simple'
        }
    },
    Watch: {},
    created() {
        this.setData()
        this.getData()
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },

        setData() {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue
                }
                this.postData[i] = this.$route.query[i]
            }
        },
        getData() {
            // 查询数据
            this.http('/manage/news/info', this.postData, (res) => {
                this.data = res.data
                this.config = res.config

                if (res.data.newsid) {
                    this.data.image = res.data.image
                    this.data.imageStatus = 1
                    this.visible = true
                    this.html = res.data.content;

                    console.log(res.data)

                    this.form.setFieldsValue({
                        title: res.data.title,
                        ncid: res.data.ncid,
                        description: res.data.description,
                        fromurl: res.data.fromurl,
                        addtime: res.data.addtime_str ? moment(res.data.addtime_str, this.dateFormat) : null,
                    })
                } else {
                    // 默认类型
                    for (let i in res.config.ncid) {
                        this.form.setFieldsValue({ncid: res.config.ncid[i].ncid})
                        break
                    }
                }

            }, null, 2)
        },
        uploadImg(e) {
            this.visible = false;
            this.loading = true;

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(e.file);

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {
                let filedata = this.result.split(',')[1];
                let data = {
                    site: 'iwowke',
                    module: 'news',
                    filestatus: 0,
                    min: '400,240',
                    filedata: {
                        'tmp_name': filedata,
                        'type': e.file.type,
                        'name': e.file.name,
                        'size': e.file.size,
                    },
                };

                that.httpImg('', data, (res) => {
                    that.data.image = res.data.file;
                    that.data.imageUrl = res.data.url + '/min/1';
                    that.data.imageStatus = 0;
                    that.visible = true;
                    that.loading = false;
                });
            }
        },
        create() {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {
                        // 没有上传图片
                        if (!this.data.image) {
                            this.alert({content: '请上传广告图片'})
                            return false
                        }

                        let url = ''
                        if (this.postData.newsid > 0) {
                            values.newsid = this.postData.newsid
                            url = '/manage/news/edit'
                        } else {
                            url = '/manage/news/save'
                        }
                        values.image = this.data.image
                        values.imageStatus = this.data.imageStatus
                        values.content = this.html;
                        values.imageList = this.imageList;
                        values.addtime = values.addtime ? values.addtime.format('YYYY-MM-DD') : '';

                        this.http(url, values, (res) => {
                            this.toast({content: '操作成功'})
                            this.$router.go(-1)
                        }, null, 1)
                    }
                },
            )
        },
        uploadImage(file, insertFn) {
            // console.log(file)

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(file);

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {

                let filedata = this.result.split(',')[1]

                let data = {
                    site: 'iwowke',
                    module: 'news',
                    filestatus: 0,
                    filedata: {
                        'tmp_name': filedata,
                        'type': file.type,
                        'name': file.name,
                        'size': file.size,
                    },
                }

                that.httpImg('', data, (res) => {
                    that.imageList.push(res.data.file)
                    insertFn(res.data.url, res.data.filename, res.data.url)
                })
            }
        },
        uploadVideo(file, insertFn) {
            //console.log(file)

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(file);

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {

                let filedata = this.result.split(',')[1]

                let data = {
                    site: 'iwowke',
                    module: 'news',
                    filestatus: 0,
                    filedata: {
                        'tmp_name': filedata,
                        'type': file.type,
                        'name': file.name,
                        'size': file.size,
                    },
                }

                that.httpImg('', data, (res) => {
                    that.imageList.push(res.data.file)
                    insertFn(res.data.url, res.data.filename, res.data.url)
                })
            }
        },
        uploadFile(file, insertFn) {
            //console.log(file)

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(file);

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {

                let filedata = this.result.split(',')[1]

                let data = {
                    site: 'iwowke',
                    module: 'news',
                    filestatus: 0,
                    filedata: {
                        'tmp_name': filedata,
                        'type': file.type,
                        'name': file.name,
                        'size': file.size,
                    },
                }

                that.httpImg('', data, (res) => {
                    that.imageList.push(res.data.file)
                    insertFn(res.data.filename, res.data.url)
                })
            }
        },
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
