import layout from '@/components/common/layout';
import jobList from '../views/job/list';

export default [
    {
        path: '/job',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "职位管理",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '137',// 父选项
                        child: 'job_list',// 子选项
                    }
                },
                component: jobList,
            },
        ]
    },
];
