// 个人中心
import layout from '@/components/common/layout';
import center from '../views/center/center';
import centerIndex from '../views/center/index';

export default [
    {
        path: '/',
        meta: {
            title: '个人中心',
        },
        component: center
    },
    {
        path: '/center',
        component: layout,
        children: [
            {
                path: '',
                meta: {
                    metaInfo: {
                        title: "个人中心",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    /*menu: {
                        parent: '',// 父选项
                        child: '',// 子选项
                    },*/
                },
                component: center,
            },
            {
                path: 'index',
                meta: {
                    metaInfo: {
                        title: "首页",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '117',// 父选项
                        child: '',// 子选项
                    },
                },
                component: centerIndex,
            },
        ]
    },
];
