<template>
    <div class="wrap">
        <listHeader title="个人中心" :scenario="scenario" @optionFun="headerOptionFun"></listHeader>
        <div class="job-detail-column">
            <div class="center-wrap">
                <div class="user-change-photo flex align-items">
                    <div class="center-user-photo">
                        <img :src="userinfo.avatarurl" @error="setErrorImg">
                        <!--<img src="../../assets/img/default_user_avatar.png" v-else/>-->
                        <!-- 悬浮层 -->
                        <div v-if="check_limit('user_edit_avatar')" class="upload-photo-layer">
                            <input type="file" @change="uploadImg">
                            <p>修改头像</p>
                        </div>
                    </div>
                    <div class="user-name-info">
                        <h1>{{ userinfo.name }}</h1>
                        <h3>{{ userinfo.rolename }}</h3>
                    </div>
                </div>
                <div class="center-user-set-info">
                    <a-row>
                        <a-col :lg="3" :xl="2" :xxl="2">
                            <div class="set-info"><p>用户名</p></div>
                        </a-col>
                        <a-col :lg="8" :xl="8" :xxl="6">
                            <div class="set-info"><p>{{ userinfo.username }}</p></div>
                        </a-col>
                        <a-col :lg="10" :xl="12" :xxl="8">
                            <div class="set-info"><p>牢记用户名，用于登录帐户</p></div>
                        </a-col>
                        <a-col :lg="3" :xl="2" :xxl="2">
                            <div class="set-info set-info-aciton flex align-items">
                                <!--<p class="green-text">已设置</p>-->
                                <!--<a href="">修改</a>-->
                            </div>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :lg="3" :xl="2" :xxl="2">
                            <div class="set-info"><p>登陆密码</p></div>
                        </a-col>
                        <a-col :lg="8" :xl="8" :xxl="6">
                            <div class="set-info flex align-items">
                                <p>******</p>
                                <!--<p>安全等级</p>-->
                                <!--<div class="safe-rank flex low in high"><span></span><span></span><span></span></div>-->
                            </div>
                        </a-col>
                        <a-col :lg="10" :xl="12" :xxl="8">
                            <div class="set-info"><p>建议定期更换密码，设置一个包含字母，符号或数字中至少两项且长度超过6位的密码</p></div>
                        </a-col>
                        <a-col :lg="3" :xl="2" :xxl="2">
                            <div class="set-info set-info-aciton flex align-items">
                                <!--<p class="green-text">已设置</p>-->
                                <a v-if="check_limit('user_edit_passwd')" href="javascript:void(0);"
                                   @click="showModalEditPasswd = true">修改</a>
                            </div>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :lg="3" :xl="2" :xxl="2">
                            <div class="set-info"><p>绑定手机号</p></div>
                        </a-col>
                        <a-col :lg="8" :xl="8" :xxl="6">
                            <div class="set-info"><p>{{ userinfo.mobile }}</p></div>
                        </a-col>
                        <a-col :lg="10" :xl="12" :xxl="8">
                            <div class="set-info"><p>您的手机为安全手机，可以用于登陆及找回密码</p></div>
                        </a-col>
                        <a-col :lg="3" :xl="2" :xxl="2">
                            <div class="set-info set-info-aciton flex align-items">
                                <!--<p class="green-text">已设置</p>-->
                                <a v-if="check_limit('user_edit_mobile')" href="javascript:void(0);"
                                   @click="showModalEditMobile = true">修改</a>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>

        <!--修改手机号-->
        <accountEditMobile
            :visible="showModalEditMobile"
            @changeModal="changeModal($event, 1)"
            @confirm="getData">
        </accountEditMobile>
        <!--修改密码-->
        <accountEditPasswd
            :visible="showModalEditPasswd"
            @changeModal="changeModal($event, 2)"
            @confirm="getData">
        </accountEditPasswd>
    </div>
</template>

<script>
import {Icon} from 'ant-design-vue';

const IconFont = Icon.createFromIconfontCN({
    scriptUrl: process.env.VUE_APP_ICONURL,
});
import listHeader from '@/components/list/listHeader';
import accountEditMobile from '@/components/modal/accountEditMobile';
import accountEditPasswd from '@/components/modal/accountEditPasswd';

export default {
    components: {
        IconFont,
        listHeader,
        accountEditMobile,
        accountEditPasswd,
    },
    data() {
        return {
            showModalEditMobile: false,// 修改手机号
            showModalEditPasswd: false,// 修改密码
            userinfo: {},
            scenario: 'center',
        }
    },
    created() {
        this.$store.state.showLayoutMenu = false;
        this.getData();
    },
    methods: {
        getData() {
            this.http('/manage/manager/info', {}, (res) => {
                this.userinfo = res.userinfo;
            }, null, 2);
        },
        changeModal(e, type) {
            switch (type) {
                case 1:
                    this.showModalEditMobile = e;
                    break;
                case 2:
                    this.showModalEditPasswd = e;
                    break;
                default:
                    break;
            }
        },
        // 错误加载默认图像
        setErrorImg(e) {
            e.target.src = '/images/default_user_avatar.png';
        },
        uploadImg(e) {
            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {
                let filedata = this.result.split(',')[1];
                let data = {
                    site: 'iwowke',
                    module: 'avatar',
                    filestatus: 1,
                    min: '300,300',
                    filedata: {
                        'tmp_name': filedata,
                        'type': e.target.files[0].type,
                        'name': e.target.files[0].name,
                        'size': e.target.files[0].size,
                    },
                };

                that.httpImg('', data, (res) => {
                    that.http('/manage/manager/edit-avatar', {avatar: res.data.file}, (re) => {
                        that.removeCookie('avatarurl');
                        that.setCookie('avatarurl', res.data.url + '/min/1');
                        that.getData();
                    });
                });

                e.target.value = '';
            };
        },
        // 头部操作
        headerOptionFun(e) {
            switch (e.op) {
                case 'logout':// 退出登录
                    this.logout();
                    break;
                default:
                    break;
            }
        },
        logout() {
            this.http('/manage/login/logout', {}, (res) => {
                this.logoutRemoveCookie();
                this.$router.push('/manager/login');
            }, null, 1);
        },
    }
}
</script>

<style lang="less" src="../../assets/css/center/center.less"></style>
