import layout from '@/components/common/layout';
import qiyeList from '../views/qiyead/list';
import qiyeCLassList from '../views/qiyead/list-class';
import qiyeCreate from '../views/qiyead/create';

export default [
  {
    path: '/qiyead',
    component: layout,
    children: [
      {
        path: 'list',
        meta: {
          metaInfo: {
            title: "广告管理",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '22',// 父选项
            child: 'qiyead_list',// 子选项
          }
        },
        component: qiyeList,
      },
      {
        path: 'list-class',
        meta: {
          metaInfo: {
            title: "广告分类",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '22',// 父选项
            child: 'qiyead_class_list',// 子选项
          }
        },
        component: qiyeCLassList,
      },
      {
        path: 'create',
        meta: {
          metaInfo: {
            title: "新增广告",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '22',// 父选项
            child: 'qiyead_list',// 子选项
          }
        },
        component: qiyeCreate,
      }
    ]
  },
];
