<template>
    <div class="wrap">
        <listHeader :title="postData.pid > 0 ? '编辑项目' : '新增项目'"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-column-title flex align-items"><span class="line"></span>
                    <h3>项目信息</h3></div>
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="项目名称"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入项目名称"
                                             v-decorator="['pname', {rules: [{ required: true, max: 128, message: '请输入项目名称, 最大128个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item label="报名开始" v-bind="formItemLayout">
                                    <a-date-picker style="width: 100%" placeholder="请选择报名截止日期"
                                                   v-decorator="['stime',{rules: [{ required: false, message: '请选择报名开始日期' }]} ]"/>
                                </a-form-item>

                                <a-form-item label="报名截止" v-bind="formItemLayout">
                                    <a-date-picker style="width: 100%" placeholder="请选择报名截止日期"
                                                   v-decorator="['etime',{rules: [{ required: false, message: '请选择报名截止日期' }]} ]"/>
                                </a-form-item>

                                <a-form-item label="打印开始" v-bind="formItemLayout">
                                    <a-date-picker style="width: 100%" placeholder="请选择报名截止日期"
                                                   v-decorator="['prints',{rules: [{ required: false, message: '请选择报名开始日期' }]} ]"/>
                                </a-form-item>

                                <a-form-item label="打印截止" v-bind="formItemLayout">
                                    <a-date-picker style="width: 100%" placeholder="请选择报名截止日期"
                                                   v-decorator="['printe',{rules: [{ required: false, message: '请选择报名截止日期' }]} ]"/>
                                </a-form-item>

                                <a-form-item label="成绩查询日期" v-bind="formItemLayout">
                                    <a-date-picker style="width: 100%" placeholder="请选择成绩查询日期"
                                                   v-decorator="['querytime',{rules: [{ required: false, message: '请选择成绩查询日期' }]} ]"/>
                                </a-form-item>

                                <a-form-item
                                    label="笔试费用"
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请填写笔试费用"
                                             v-decorator="['wrfee', {rules: [{ required: false, validator: checkMoney, message: '请填写笔试费用' }]} ]"/>
                                </a-form-item>

                                <a-form-item
                                    label="缴费截止"
                                    v-bind="formItemLayout"
                                >
                                    <a-date-picker style="width: 100%" placeholder="请选择缴费截止日期"
                                                   v-decorator="['wrtime',{rules: [{ required: false, message: '请选择缴费截止日期' }]} ]"/>
                                </a-form-item>

                                <a-form-item
                                    label="面试费用"
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请填写面试费用"
                                             v-decorator="['infee', {rules: [{ required: false, validator: checkMoney, message: '请填写面试费用' }]} ]"/>
                                </a-form-item>

                                <a-form-item
                                    label="缴费截止"
                                    v-bind="formItemLayout"
                                >
                                    <a-date-picker style="width: 100%" placeholder="请选择缴费截止日期"
                                                   v-decorator="['intime',{rules: [{ required: false, message: '请选择缴费截止日期' }]} ]"/>
                                </a-form-item>

                                <a-form-item
                                    v-bind="formBtnLayout"
                                >
                                    <a-button type="primary" @click="create">确定</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader';
import moment from 'moment';

export default {
    components: {
        listHeader
    },
    data() {
        return {
            loading: false, // 显示上传中
            formItemLayout: {
                labelCol: {
                    span: 5
                },
                wrapperCol: {
                    span: 19
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                'pid': 0,
            },
            data: {},
            config: {},
            dateFormat: 'YYYY-MM-DD',
        }
    },
    Watch: {},
    created() {
        this.setData();
        this.getData();
    },
    methods: {
        setData() {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) continue;
                this.postData[i] = this.$route.query[i];
            }
        },
        getData() {
            // 查询数据
            this.http('/manage/project/info', this.postData, (res) => {
                this.data = res.data;
                this.config = res.config;

                if (res.data.pid) {

                    this.$nextTick(() => {

                        this.form.setFieldsValue({
                            pname: res.data.pname,
                            wrfee: res.data.wrfee,
                            infee: res.data.infee,
                            stime: res.data.stime_str ? moment(res.data.stime_str, this.dateFormat) : null,
                            etime: res.data.etime_str ? moment(res.data.etime_str, this.dateFormat) : null,
                            prints: res.data.prints_str ? moment(res.data.prints_str, this.dateFormat) : null,
                            printe: res.data.printe_str ? moment(res.data.printe_str, this.dateFormat) : null,
                            wrtime: res.data.wrtime_str ? moment(res.data.wrtime_str, this.dateFormat) : null,
                            intime: res.data.intime_str ? moment(res.data.intime_str, this.dateFormat) : null,
                            querytime: res.data.querytime_str ? moment(res.data.querytime_str, this.dateFormat) : null,
                        });
                    });
                }

            }, null, 2);
        },
        create() {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        console.log(values)

                        let url = '';
                        if (this.postData.pid > 0) {
                            values.pid = this.postData.pid;
                            url = '/manage/project/edit';
                        } else {
                            url = '/manage/project/save';
                        }
                        values.stime = values.stime ? values.stime.format('YYYY-MM-DD') : '';
                        values.etime = values.etime ? values.etime.format('YYYY-MM-DD') : '';
                        values.prints = values.prints ? values.prints.format('YYYY-MM-DD') : '';
                        values.printe = values.printe ? values.printe.format('YYYY-MM-DD') : '';
                        values.wrtime = values.wrtime ? values.wrtime.format('YYYY-MM-DD') : '';
                        values.intime = values.intime ? values.intime.format('YYYY-MM-DD') : '';
                        values.querytime = values.querytime ? values.querytime.format('YYYY-MM-DD') : '';

                        this.http(url, values, (res) => {
                            this.toast({content: '操作成功'});
                            this.$router.go(-1);
                        }, null, 1);
                    }
                }
            );
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
