<template>
    <div class="wrap">
        <listHeader :title="title" :scenario="scenario" @optionFun="headerOptionFun"></listHeader>

        <div class="wrap-list-content">
            <search-screen
                :checkData="postData"
                :set-condition="{
            pagesize: setCondition.pagesizes,
            field: setCondition.fields,
        }"
                :config="config"
                :scenario="scenario"
                @exportRes="exportRes(0)"
                @setOption="setOption"
                @clear="clear"
                @search="search"
            >
            </search-screen>

            <div class="hro-table hro-table-only">
                <hro-table
                    :columns="columns"
                    :dataSource="data"
                    :loading="loading"
                    :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                    :scroll="scroll"
                    :rowKey="selectedKey"
                    @actionFun="actionFun"
                >

                </hro-table>
            </div>
        </div>

        <list-bottom
            :page="postData.page"
            :pagesize="postData.pagesize"
            :total="total"
            :data="data"
            :selectedRowKeys="selectedRowKeys"
            :condition="{
          checkall: true,
      }"
            :scenario="scenario"
            @checked="onCheckAllChange"
            @page="pageTo"
            @optionFun="bottomOptionFun"
        >
        </list-bottom>
    </div>
</template>

<script>
import $ from 'jquery';
import searchScreen from '@/components/list/searchScreen';
import listBottom from '@/components/list/listBottom';
import hroTable from '@/components/list/table';
import listHeader from '@/components/list/listHeader';

export default {
    components: {
        listBottom,
        hroTable,
        searchScreen,
        listHeader,
    },
    data() {
        return {
            title: '新闻管理',
            scenario: 'newsList', // 页面标识
            selectedRowKeys: [], // 选中行的key
            selectedRowKeysItem: [], // 选中行的item
            selectedKey: 'newsid', // 数据主键字段（唯一）
            loading: false,// 请求
            scroll: null, //滚动
            data: [],
            setCondition: {
                fields: [],// 显示字段
                pagesizes: {},// 每页显示数筛选条件
            },
            postData: {
                keyword: '',
                istop: '',
                ncid: '',
                status: '',
                page: 1,
                pagesize: 10,
                showfield: [], // 显示字段
            },
            total: 0,
            config: {},
            showModalEdit: false, // 弹框
            modelType: 0,
        }
    },
    computed: {
        columns() {
            // 处理显示字段
            let columns = [];
            for (let i in this.setCondition.fields) {
                let index = this.inArray(this.setCondition.fields[i].key, this.postData.showfield);
                if (index > -1) {
                    let item = {
                        title: this.setCondition.fields[i].text,
                        dataIndex: this.setCondition.fields[i].key,
                    };

                    if (i == 0 && this.setCondition.fields[i].default == 1) {
                        item.fixed = 'left';
                        item.width = 100;
                    }

                    if (this.setCondition.fields[i].key == 'title') {
                        item.width = 300;
                        item.scopedSlots = {customRender: 'news_title'};
                    }

                    if (this.setCondition.fields[i].key == 'image') {
                        item.scopedSlots = {customRender: 'news_image'};
                    }

                    columns.push(item);
                }
            }

            let item = {
                title: '操作',
                dataIndex: 'news_action',
                scopedSlots: {customRender: 'news_action'},
            };

            let len = columns.length;
            if (len > 7) {
                item.fixed = 'right';
                item.width = 200;
            } else if (len > 0 && len < 7) {
                columns[0].fixed = '';
                columns[0].width = 100;
            }
            columns.push(item);

            let x = 300 + (len - 2) * 150;
            let table_width = $('.hro-table').width();
            this.scroll = x > table_width ? {x: x} : null;

            return columns;
        }
    },
    watch: {
        '$store.state.showLayoutMenu'(val) {
            $('.list-bottom').width($('.wrap-list-content').width());
        },
    },
    created() {
        // 当浏览器大小变化时
        this.listenListWindow();

        // 初始化postData
        this.setData();
        // 请求数据
        this.getData();
    },
    methods: {
        getData() {
            // 初始化选中
            this.selectedRowKeys = [];

            // 加载等待
            this.loading = true;
            // 路由变化
            this.changeRouter();
            this.http('/manage/news/list', this.postData, (res) => {
                this.loading = false;

                // 数据渲染
                this.setCondition.fields = res['_fields'];
                this.setCondition.pagesizes = res['_pagesizes'];
                this.data = res.list.data;
                this.total = parseInt(res.list.count);
                this.postData.pagesize = parseInt(res.list.pagesize);
                this.config = res.config;

                // 初始化底部宽度
                this.$nextTick(() => {
                    $('.list-bottom').width($('.wrap-list-content').width());
                });
            }, null, 2);
        },
        // 设置参数
        setData() {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) continue;

                if (i === 'showfield') {
                    this.postData[i] = typeof this.$route.query[i] === 'string' ? [this.$route.query[i]] : this.$route.query[i];
                } else {
                    this.postData[i] = this.$route.query[i];
                }
            }

            // 初始化显示字段
            if (this.$route.query['showfield'] === undefined) {
                this.postData['showfield'] = ['ncid_str', 'image', 'title', 'viewnum', 'status_str', 'muname', 'addtime_str'];
            }
        },
        // 路由监听
        changeRouter() {
            this.$router.push({path: '', query: this.postData});
        },
        // 头部操作
        headerOptionFun(e) {
            switch (e.op) {
                case 'create': // 导入户
                    this.$router.push('/news/create');
                    break;
                default:
                    break;
            }
        },
        // 列表操作
        actionFun(e) {
            switch (e.op) {
                case 'edit':// 编辑
                    this.$router.push('/news/create?newsid=' + e[this.selectedKey]);
                    break;
                case 'close':// 关闭
                    this.confirm({title: '关闭新闻', content: `确认关闭该新闻？`}, () => {
                        this.http('/manage/news/close', {newsid: e[this.selectedKey]}, (res) => {
                            this.toast({content: '操作成功'});
                            this.getData();
                        }, null, 1);
                    });
                    break;
                case 'open':// 开启
                    this.confirm({title: '恢复新闻', content: `确认恢复该条新闻？`}, () => {
                        this.http('/manage/news/open', {newsid: e[this.selectedKey]}, (res) => {
                            this.toast({content: '操作成功'});
                            this.getData();
                        }, null, 1);
                    });
                    break;
                case 'top':// 置顶
                    this.confirm({title: '置顶新闻', content: `确认置顶该新闻？`}, () => {
                        this.http('/manage/news/top', {newsid: e[this.selectedKey]}, (res) => {
                            this.toast({content: '操作成功'});
                            this.getData();
                        }, null, 1);
                    });
                    break;
                case 'untop':// 取消置顶
                    this.confirm({title: '取消新闻置顶', content: `确认取消新闻置顶？`}, () => {
                        this.http('/manage/news/untop', {newsid: e[this.selectedKey]}, (res) => {
                            this.toast({content: '操作成功'});
                            this.getData();
                        }, null, 1);
                    });
                    break;
                default:
                    break;
            }
        },
        // 底部操作
        bottomOptionFun(e) {
            if (this.selectedRowKeys.length === 0) {
                return false;
            }

            switch (e.op) {
                default:
                    break;
            }
            this.showModalEdit = true;
        },
        changeModal(e, modelType) {
            switch (modelType) {
                default:
                    break;
            }
        },
    },
}
</script>


<style lang="less" src="../../assets/css/list/list.less"></style>
