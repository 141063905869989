import layout from '@/components/common/layout';
import projectList from '../views/project/list';
import projectCreate from '../views/project/create';

export default [
    {
        path: '/project',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "项目管理",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '137',// 父选项
                        child: 'project_list',// 子选项
                    }
                },
                component: projectList,
            },
            {
                path: 'create',
                meta: {
                    metaInfo: {
                        title: "新增项目",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '137',// 父选项
                        child: 'project_list',// 子选项
                    }
                },
                component: projectCreate,
            }
        ]
    },
];
