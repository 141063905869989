<template>
    <a-layout id="components-layout-demo-custom-trigger">
        <a-layout-sider
                :trigger="null"
                collapsible
                collapsedWidth="54px"
                v-model="collapsed"
                :style="{ overflowX: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0,}"
                width="104px"
                class="hro-slider"
                v-if="$store.state.showMenu == true"
        >
            <!--@mouseover="blockCollapsed()"
            @mouseleave="hiddenCollapsed()"-->
            <div class="logo flex align-items" @click="homeTo">
                <img src="../../assets/img/left_logo.png" alt="" width="32" height="32">
            </div>

            <a-menu theme="dark" mode="inline" v-model="defaultParentKeys" class="menu-list flex1">
                <a-menu-item v-for="item in menulist" :key="item.limitid" @click="showSubMenuShow">
                    <icon-font :type="item.icon" />
                    <span>{{ item.name }}</span>
                </a-menu-item>
            </a-menu>

            <div v-if="check_limit('user_info')" class="user-photo">
                <a href="javascript:void(0);" class="flex align-items" @click="centerTo">
                    <img :src="$cookies.get('avatarurl') ? $cookies.get('avatarurl') : 'undefined'" @error="setErrorImg"/>
                    <span class="word-hidden">{{ $cookies.get('name') }}</span>
                </a>
            </div>
        </a-layout-sider>

        <!-- 二级导航 -->
        <div class="hro-sub-menu" :class="{'sub-menu-show': $store.state.showLayoutMenu}" v-if="$store.state.showMenu == true">
            <h3>{{ title }}</h3>
            <a-menu
                mode="inline"
                style="width: 180px"
                :openKeys="openKeys"
                v-model="defaultChildKeys"
                @openChange="onOpenChange"
            >
                <template v-for="pitem in menulist">
                    <a-sub-menu
                        :key="item.limitid"
                        :title="item.name"
                        v-for="item in pitem.child"
                        v-if="defaultParentKeys[0] === pitem.limitid"
                    >
                        <a-menu-item :key="child.flag" v-for="child in item.child" @click="locationTo(child.url)">
                            {{ child.name }}
                        </a-menu-item>
                    </a-sub-menu>
                </template>
            </a-menu>

            <div class="sub-head-trigger-icon" :class="{'sub-head-icon-show': $store.state.showLayoutMenu }" @click="toggleSubMenuShow()">
                <span></span>
            </div>
        </div>

        <div :style="{background: '#fff', minHeight: 'calc(100vh)', position: 'absolute', top: 0, bottom: 0, right: 0, padding: '0' }" :class="$store.state.showLayoutMenu ? sliderLeft104 : $store.state.showMenu ? sliderLeft54 : sliderLeft0">
            <router-view/>
        </div>
    </a-layout>
</template>

<script>
    import { Icon } from 'ant-design-vue';
    const IconFont = Icon.createFromIconfontCN({
        scriptUrl: process.env.VUE_APP_ICONURL,
    });

    export default {
        components: {
            IconFont
        },
        data() {
            return {
                collapsed: true,
                sliderLeft54: 'sliderLeft54',
                sliderLeft104: 'sliderLeft104',
                sliderLeft0: 'sliderLeft0',
                showMenu: this.$localStorage.get('showMenu') === null ? true : this.$localStorage.get('showMenu') == 1 ? true : false,
                showLayoutMenu: this.$localStorage.get('showLayoutMenu') === null ? true : this.$localStorage.get('showLayoutMenu') == 1 ? true : false,
                defaultParentKeys: ['1'],// 父选中
                openKeys: [],// 默认打开
                defaultChildKeys: [],// 子选中
                menulist: [],
            }
        },
        computed: {
            title() {
                let title = '';
                for(let i in this.menulist) {
                    if(this.menulist[i].limitid === this.defaultParentKeys[0]) {
                        title = this.menulist[i].name;
                        break;
                    }
                }
                return title;
            },
            rootSubmenuKeys() {
                let keys = [];
                for (let i in this.menulist) {
                    for (let j in this.menulist[i].child) {
                        keys.push(this.menulist[i].child[j].limitid);
                    }
                }
                return keys;
            },
        },
        watch: {
            '$route'(val) {
                if(this.$route.meta.menu) {
                    if (this.$route.meta.menu.parent !== undefined) {
                        this.defaultParentKeys[0] = this.$route.meta.menu.parent;
                    }
                    if (this.$route.meta.menu.child !== undefined) {
                        this.defaultChildKeys[0] = this.$route.meta.menu.child;
                        // this.openKeys[0] = this.computedOpenkeys();
                    }
                }
            },
            // 切换父级菜单默认展开第一个选项卡，被选中则展开选中
            defaultParentKeys(val) {
                let isset = false;

                for (let i in this.menulist) {
                    if(isset === true) {
                        break;
                    }
                    // 父Item
                    let pitem = this.menulist[i];
                    if(pitem.limitid != val[0]) {
                        continue;
                    }

                    let openKeys = [];
                    for (let j in pitem.child) {
                        /*// Item
                        let item = pitem.child[j];

                        if(this.$route.meta.menu.parent != val[0]) {
                            this.openKeys[0] = item.limitid;
                            isset = true;
                            break;
                        } else {

                            for (let k in item.child) {
                                // 子Item
                                let citem = item.child[k];

                                if(citem.flag === this.$route.meta.menu.child) {
                                    this.openKeys[0] = item.limitid;
                                    isset = true;
                                    break;
                                }
                            }
                        }*/
                        let item = pitem.child[j];
                        openKeys.push(item.limitid);
                    }
                    this.openKeys = openKeys;
                }
            }
        },
        created() {
            this.menulist = this.getObjectLocalStorage('community_menulist');

            if(!this.menulist) {
                this.getMenuList();
            } else {
                this.initParam();
            }

            this.$store.state.showLayoutMenu = this.showLayoutMenu;
            this.$store.state.showMenu = this.showMenu;
        },
        methods: {
            homeTo() {
                this.$router.push('/center/index');
            },
            // 默认选中
            initParam() {
                if(this.$route.meta.menu) {
                    if (this.$route.meta.menu.parent !== undefined) {
                        this.defaultParentKeys[0] = this.$route.meta.menu.parent;
                    }
                    if (this.$route.meta.menu.child !== undefined) {
                        this.defaultChildKeys[0] = this.$route.meta.menu.child;
                        // this.openKeys[0] = this.computedOpenkeys();
                        this.openKeys = this.computedOpenkeys();
                    }
                }
            },
            getMenuList() {
                this.http('/manage/manager/menus', {}, (res) => {
                    this.menulist = res.menulist;
                    this.initParam();
                    this.setObjectLocalStorage('community_menulist', res.menulist);
                    this.setObjectLocalStorage('community_limit', res.limitflag);
                });
            },
            // 左侧导航
            blockCollapsed () {
                this.collapsed = false;
            },
            hiddenCollapsed () {
                this.collapsed = true;
            },
            // 计算默认openkeys
            computedOpenkeys() {
                for (let i in this.menulist) {
                    // 父Item
                    let pitem = this.menulist[i];
                    if(pitem.limitid != this.defaultParentKeys[0]) {
                        continue;
                    }
                    let openKeys = [];
                    for (let j in pitem.child) {
                        /*// Item
                        let item = pitem.child[j];
                        for (let k in item.child) {
                            // 子Item
                            let citem = item.child[k];

                            if(citem.flag === this.defaultChildKeys[0]) {
                                return item.limitid;
                            }
                        }*/
                        let item = pitem.child[j];
                        openKeys.push(item.limitid);
                    }
                    return openKeys;
                }

                // return this.rootSubmenuKeys[0];
                return [];
            },
            // 监听二级菜单切换
            onOpenChange (openKeys) {
                this.openKeys = openKeys;
                /*const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
                if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                    this.openKeys = openKeys;
                } else {
                    this.openKeys = latestOpenKey ? [latestOpenKey] : []
                }*/
            },
            // 切换左侧菜单显示or隐藏
            toggleSubMenuShow () {
                this.$store.state.showLayoutMenu = !this.$store.state.showLayoutMenu;
                this.showLayoutMenu = this.$store.state.showLayoutMenu;
                this.$localStorage.set('showLayoutMenu', this.showLayoutMenu ? 1 : 0);
            },
            showSubMenuShow() {
                this.showLayoutMenu = true;
                this.$localStorage.set('showLayoutMenu', 1);
                this.$store.state.showLayoutMenu = this.showLayoutMenu;
            },
            locationTo(url) {
                // 选中不可点击
                if(url.indexOf(this.$route.path) === -1 || url != this.$route.path) {
                    this.$router.push(url);
                }
            },
            centerTo() {
                this.$router.push('/center');
            },
            // 错误加载默认图像
            setErrorImg(e) {
                e.srcElement.src = '/images/default_user_avatar.png';
            },
        },
    }
</script>

<style lang="less" src="../../assets/css/init/layout.less"></style>
