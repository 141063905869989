<template>
    <div class="search-screen flex align-items">
        <div class="search-screen-left">
            <a-form layout="inline">

                <!--报名管理-->
                <template v-if="scenario == 'enterList'">
                    <a-form-item>
                        <a-input type="text" placeholder="姓名关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">初审状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.wrfee">
                            <a-select-option value="">笔试缴费</a-select-option>
                            <a-select-option v-for="(item, key) in config.wrfee" :key="key" :value="key.toString()">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.instatus">
                            <a-select-option value="">面试状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.instatus" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.infee">
                            <a-select-option value="">面试缴费</a-select-option>
                            <a-select-option v-for="(item, key) in config.infee" :key="key" :value="key.toString()">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.result">
                            <a-select-option value="">录取结果</a-select-option>
                            <a-select-option v-for="(item, key) in config.result" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--公告管理-->
                <template v-if="scenario == 'knewsList'">
                    <a-form-item>
                        <a-input type="text" placeholder="标题关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.type">
                            <a-select-option value="">类型</a-select-option>
                            <a-select-option v-for="(item, key) in config.type" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--职位管理-->
                <template v-if="scenario == 'jobList'">
                    <a-form-item>
                        <a-input type="text" placeholder="职位关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--项目管理-->
                <template v-if="scenario == 'projectList'">
                    <a-form-item>
                        <a-input type="text" placeholder="项目关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--广告管理-->
                <template v-if="scenario == 'adList'">
                    <a-form-item>
                        <a-input type="text" placeholder="广告描述关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.adcid">
                            <a-select-option value="">广告分类</a-select-option>
                            <a-select-option v-for="(item, key) in config.adcid" :key="key" :value="key">{{
                                    item.adcname
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--广告分类管理-->
                <template v-if="scenario == 'adClassList'">
                    <a-form-item>
                        <a-input type="text" placeholder="广告分类关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--企业管理-->
                <template v-if="scenario == 'qiyeList'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业名称关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.qcid">
                            <a-select-option value="">企业分类</a-select-option>
                            <a-select-option v-for="(item, key) in config.qcid" :key="key" :value="key">{{
                                    item.qcname
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--广告分类管理-->
                <template v-if="scenario == 'qiyeClassList'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业分类关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--新闻管理-->
                <template v-if="scenario == 'newsList'">
                    <a-form-item>
                        <a-input type="text" placeholder="新闻标题关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.ncid">
                            <a-select-option value="">新闻分类</a-select-option>
                            <a-select-option v-for="(item, key) in config.ncid" :key="key" :value="key">{{
                                    item.ncname
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.istop">
                            <a-select-option value="">置顶</a-select-option>
                            <a-select-option v-for="(item, key) in config.tops" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--新闻分类管理-->
                <template v-if="scenario == 'newsClassList'">
                    <a-form-item>
                        <a-input type="text" placeholder="新闻分类关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--主账户管理-->
                <template v-if="scenario == 'AccountManage'">
                  <a-form-item>
                    <a-input type="text" placeholder="企业名称" v-model="postData.keyword"></a-input>
                  </a-form-item>

                  <a-form-item>
                    <a-select v-model="postData.status">
                      <a-select-option value="">状态</a-select-option>
                      <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{ item }}</a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item>
                    <a-select v-model="postData.viptype">
                      <a-select-option value="">会员类型</a-select-option>
                      <a-select-option v-for="(item, key) in config.viptype" :key="key" :value="key">{{ item }}</a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item>
                    <a-date-picker style="width:130px" placeholder="会员到期日期" v-model="postData.date" />
                  </a-form-item>
                </template>

                <!--用户管理-->
                <template v-if="scenario == 'accountList'">
                  <a-form-item>
                    <a-input type="text" placeholder="姓名关键词" v-model="postData.keyword"></a-input>
                  </a-form-item>

                  <a-form-item>
                    <a-select v-model="postData.status">
                      <a-select-option value="">状态</a-select-option>
                      <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{ item }}</a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item>
                    <a-select v-model="postData.roleid">
                      <a-select-option value="">角色名称</a-select-option>
                      <a-select-option v-for="(item, key) in config.roleid" :key="item.roleid" :value="item.roleid">{{ item.rolename }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </template>

                <a-form-item>
                    <a-button-group>
                        <a-button @click="clear">重置</a-button>
                        <a-button type="primary" @click="search">搜索</a-button>
                    </a-button-group>
                </a-form-item>
            </a-form>
        </div>
        <div class="search-screen-right">
            <a href="javascript:void(0);" v-if="showExport" @click="exportRes">
                <icon-font type="icontool_export"/>
                导出结果
            </a>
            <a href="javascript:void(0);" @click="showModal()" v-if="showSetCondition">
                <icon-font type="icontool_set"/>
                显示设置
            </a>
        </div>

        <!-- 弹框  -->
        <a-modal
            okText="确定"
            cancelText="取消"
            v-model="visible"
            @ok="handleOk"
            @cancel="closeSetModal"
            :closable=false
            class="modal-initialize"
            width="700px"
            v-if="showSetCondition"
        >
            <template slot="title">
                <div class="modal-title flex">
                    <h4>显示设置</h4>
                    <icon-font type="iconclose" @click="closeSetModal"/>
                </div>
            </template>
            <div class="display-modal-body">
                <div class="choose-pageno hro-modal-title">
                    <h4>每页显示数量</h4>
                    <div class="pageno-btn-list">
                        <a-button
                            type="primary"
                            size="small"
                            v-for="(item, key) in setCondition.pagesize"
                            :key="key"
                            :class="{'grey-btn': parseInt(item) != modalData.pagesize}"
                            @click="modalData.pagesize = parseInt(item)">
                            {{ item }}
                        </a-button>
                    </div>
                </div>
                <div class="choose-table-title hro-modal-title">
                    <h4>选择列表显示的标题信息</h4>
                    <div class="title-groups">
                        <a-checkbox-group @change="onChange" :value="modalData.showfield">
                            <a-row>
                                <a-col
                                    :span="6"
                                    v-for="(item, key) in setCondition.field"
                                    :key="key"
                                >
                                    <a-checkbox :value="item.key" :disabled="item.default == 1">{{
                                            item.text
                                        }}
                                    </a-checkbox>
                                </a-col>
                            </a-row>
                        </a-checkbox-group>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment';
import {Icon} from 'ant-design-vue';

const IconFont = Icon.createFromIconfontCN({
    scriptUrl: process.env.VUE_APP_ICONURL,
});

export default {
    components: {
        IconFont,
    },
    props: {
        // 条件
        condition: {
            type: Object,
            default: null
        },
        // 选中数据
        checkData: {
            type: Object,
            default: null,
        },
        // 显示设置条件
        setCondition: {
            type: Object,
            default: null
        },
        // 配置
        config: {
            type: Object,
            default: null
        },
        // 场景
        scenario: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            formatYear: 'YYYY',
            modeYear: 'year',
            visible: false,
            showModalSearch: false,
            formItemLayout: {
                labelCol: {
                    span: 4
                },
                wrapperCol: {
                    span: 20
                },
            },
            postData: {},
            modalData: {
                pagesize: 0,
                showfield: [],
            },
            dateFormat: 'YYYY-MM-DD',
        }
    },
    computed: {
        // 导出
        showExport() {
            let scenarioArr = ['kaoqinList', 'entryList', 'PropertyfeeDetailList', 'BillList', 'userList'];
            if (this.inArray(this.scenario, scenarioArr) > -1) {
                return true;
            } else {
                return false;
            }
        },
        // 显示设置
        showSetCondition() {
            return true;
            /*
            let scenarioArr = ['myProjectList', 'projectList', 'visitList', 'assignList', 'myClassList', 'classList'];
            if(this.inArray(this.scenario, scenarioArr) > -1) {
                return true;
            } else {
                return false;
            }
            */
        },
    },
    watch: {
        checkData() {
            // 筛选数据
            this.initConditionData();
        },
        setCondition() {
            this.initModalData();
        },
    },
    created() {
        this.initConditionData();
    },
    methods: {
        // 导出结果
        exportRes() {
            this.$emit('exportRes');
        },
        // 显示设置
        showModal() {
            this.visible = true;
        },
        handleOk() {
            this.visible = false;
            this.$emit('setOption', this.modalData);
        },
        closeSetModal() {
            this.visible = false;
            this.initModalData();
        },
        // 开始日期禁用
        disabledStartDate(startValue) {
            const endValue = this.postData.eupdatetime;
            if (!startValue || !endValue) {
                return false;
            }
            return startValue.valueOf() > endValue.valueOf();
        },
        // 结束日期禁用
        disabledEndDate(endValue) {
            const startValue = this.postData.supdatetime;
            if (!endValue || !startValue) {
                return false;
            }
            return startValue.valueOf() >= endValue.valueOf();
        },
        // 初始化
        initModalData() {
            // 弹框数据
            let field = [];
            for (let i in this.setCondition.field) {
                if (this.setCondition.field[i].default == 1) {
                    field.push(this.setCondition.field[i].key);
                }
            }

            this.modalData = {
                pagesize: this.checkData.pagesize,
                showfield: this.checkData.showfield && this.checkData.showfield.length > 0 ? this.checkData.showfield : field,
            };
        },
        onChange(checkedValues) {
            this.modalData.showfield = checkedValues;
        },
        // 重置筛选
        clear() {
            this.$emit('clear');
        },
        // 筛选
        search() {
            let params = {};
            for (var i in this.postData) {
                params[i] = this.postData[i];
            }

            // 处理时间等需要定制的搜索项
            switch (this.scenario) {
                case 'kaoqinList':
                case 'entryList':
                    params.sdate = params.sdate ? params.sdate.format('YYYY-MM-DD') : '';
                    params.edate = params.edate ? params.edate.format('YYYY-MM-DD') : '';
                    break;
                case 'reportList':
                    params.date = params.date ? params.date.format('YYYY-MM') : '';
                    break;
                case 'AccountManage':
                    params.date = params.date ? params.date.format('YYYY-MM-DD') : '';
                    break;
                default:
                    break;
            }

            this.$emit('search', params);
        },
        initConditionData() {
            let params = {};
            for (var i in this.checkData) {
                params[i] = this.checkData[i];
            }

            // 处理时间等需要定制的搜索项
            switch (this.scenario) {
                case 'kaoqinList':
                case 'entryList':
                    params.sdate = params.sdate ? moment(params.sdate) : null;
                    params.edate = params.edate ? moment(params.edate) : null;
                    break;
                case 'reportList':
                    params.date = params.date ? moment(params.date) : null;
                    break;
                case 'AccountManage':
                    params.date = params.date ? moment(params.date) : null;
                    break;
                default:
                    break;
            }

            this.postData = params;
            this.postData.page = 1;
        },
        changeModal(e, type) {
            switch (type) {
                case 1:
                    this.showModalSearch = e;
                    break;
                default:
                    break;
            }
        },
    },
}
</script>

<style lang="less">
.search-screen {
    justify-content: space-between;
    padding-bottom: 30px;

    .search-screen-right {
        a {
            margin-left: 26px;

            i {
                margin-right: 8px;
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .search-screen-left {
        .ant-input {
            width: 120px;
        }
    }
}
</style>
