<template>
    <div class="user">
        <div class="login-form">
            <div class="logo"><a href="/"> <img src="../../assets/img/logo.png" alt=""></a></div>
            <div class="login-form-column">
                <a-form :form="form">
                    <a-form-item v-if="needFlag">
                        <a-input placeholder="请输入项目账户" :addonAfter="domain_str" v-decorator="['domain', {rules: [{ required: true, message: '请输入小区账户' }]}]" @keyup.enter="create"/>
                    </a-form-item>

                    <a-form-item>
                        <a-input placeholder="请输入用户名/手机号" v-decorator="['username', {rules: [{ required: true, message: '请输入用户名/手机号' }]}]" @keyup.enter="create"/>
                    </a-form-item>

                    <a-form-item>
                        <a-input :type="passwdType" placeholder="请输入登录密码" v-decorator="['passwd', {rules: [{ required: true, message: '请输入登录密码' }]}]" @keyup.enter="create">
                            <i :class="`iconfont ${showEye}`" slot="suffix" @click="toggleEye"></i>
                        </a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-button type="primary" @click="create">登录</a-button>
                    </a-form-item>

                    <!--
                    <div class="register">
                       <p>没有账户？<a href="/user/register">立即注册体验账户>></a></p>
                    </div>
                    -->
                </a-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                value: 1,
                formItemLayout: {
                    labelCol: {
                        span: 5
                    },
                    wrapperCol: {
                        span: 19
                    },
                },
                formBtnLayout: {
                    wrapperCol: {
                        span: 19,
                        offset: 5,
                    },
                },
                form: this.$form.createForm(this),
                showEye: 'iconpassword_hide',
                domain_str: '',
                domain: '',
                passwdType: 'password',
            }
        },
        computed: {
            needFlag() {
                // 使用专属域名登录
                return true;
                // return this.domain.indexOf('www') === -1 && process.env.NODE_ENV !== "development" ? false : true;
            }
        },
        watch: {
            // 显示或隐藏密码
            showEye() {
                if (this.showEye === 'iconpassword_display') {
                    this.passwdType = 'text';
                } else if (this.showEye === 'iconpassword_hide') {
                    this.passwdType = 'password';
                }
            },
        },
        created() {
            let domain = process.env.VUE_APP_DOMAIN.split('.');
            let len = domain.length;
            this.domain_str = '.' + domain[len-2] + '.' + domain[len-1];
            this.domain = document.domain;
        },
        methods: {
            // 显示隐藏密码
            toggleEye() {
                if (this.showEye === 'iconpassword_hide') {
                    this.showEye = 'iconpassword_display';
                } else {
                    this.showEye = 'iconpassword_hide';
                }
            },
            create() {
                this.form.validateFields(
                    (errors, values) => {
                        if (!errors) {

                            values.logintype = 'username';
                            if (!this.needFlag) {
                                values.domain = this.domain.split('.')[0];
                            }

                            this.http('/manage/login/loginpost', values, (res) => {
                                // this.toast({content: '创建成功'});
                                this.setObjectLocalStorage('community_menulist', res.menulist);
                                this.setObjectLocalStorage('community_limit', res.limitflag);
                                this.loginSetCookie(res.userinfo);

                                this.$router.push(res.userinfo.firstUrl);
                            }, null, 1);
                        }
                    }
                );
            },
        },
    }
</script>

<style lang="less" src="../../assets/css/center/login.less"></style>
