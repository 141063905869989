<template>
    <div class="head-info flex align-items">
        <h3>{{ title }}</h3>
        <div class="global-operation">
            <!--新增项目-->
            <template v-if="scenario === 'projectList'">
                <a-button v-if="check_limit('project_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增项目</a-button>
            </template>
            <!--发布公告-->
            <template v-else-if="scenario === 'knewsList'">
                <a-button v-if="check_limit('knews_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>发布公告</a-button>
            </template>
            <!--发布新闻-->
            <template v-else-if="scenario === 'newsList'">
                <a-button v-if="check_limit('news_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>发布新闻</a-button>
            </template>
            <!--创建分类-->
            <template v-else-if="scenario === 'newsClassList'">
                <a-button v-if="check_limit('news_class_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>创建分类</a-button>
            </template>
            <!--新增广告-->
            <template v-else-if="scenario === 'adList'">
                <a-button v-if="check_limit('ad_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增广告</a-button>
            </template>
            <!--创建分类-->
            <template v-else-if="scenario === 'adClassList'">
                <a-button v-if="check_limit('ad_class_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>创建分类</a-button>
            </template>
            <!--新增企业-->
            <template v-else-if="scenario === 'qiyeList'">
                <a-button v-if="check_limit('qiyead_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增企业</a-button>
            </template>
            <!--创建企业分类-->
            <template v-else-if="scenario === 'qiyeClassList'">
                <a-button v-if="check_limit('qiyead_class_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>创建分类</a-button>
            </template>
            <!--创建员工-->
            <template v-else-if="scenario === 'accountList'">
                <a-button v-if="check_limit('system_save_user')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增用户</a-button>
            </template>
            <!--创建角色-->
            <template v-else-if="scenario === 'roleList'">
                <a-button v-if="check_limit('system_save_role')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增角色</a-button>
            </template>
            <!--退出登录-->
            <template v-else-if="scenario === 'center'">
                <a href="javascript:void(0);" class="login-out flex align-items" @click="optionFun('logout')"><i class="iconfont iconquit"></i>退出登录</a>
            </template>
        </div>
    </div>
</template>

<script>
import { Icon } from 'ant-design-vue';
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: process.env.VUE_APP_ICONURL,
});
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        scenario: {
            type: [String],
            default: '',
        },
        postData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            checkAll: false,// 全选
            indeterminate: false,// 部分选中
        };
    },
    components: {
        IconFont,
    },
    methods: {
        // 操作
        optionFun(op) {
            this.$emit('optionFun', {scenario: this.scenario, op: op});
        },
    }
}
</script>
