import layout from '@/components/common/layout';
import knewsList from '../views/knews/list';
import knewsCreate from '../views/knews/create';

export default [
    {
        path: '/knews',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "公告管理",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '137',// 父选项
                        child: 'knews_list',// 子选项
                    }
                },
                component: knewsList,
            },
            {
                path: 'create',
                meta: {
                    metaInfo: {
                        title: "新增公告",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '137',// 父选项
                        child: 'knews_list',// 子选项
                    }
                },
                component: knewsCreate,
            }
        ]
    },
];
