<template>
    <a-modal
        title="岗位延期"
        :visible="visible"
        class="modal-initialize"
        width="508px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form class="inline-form"  :form="form">

                    <a-form-item
                        label="延期日期"
                    >
                        <a-date-picker
                            placeholder="请选择延期日期"
                            v-decorator="['etime', {rules: [{ required: false, message: '请选择延期日期' }]}]"
                        />
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        jid: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
        };
    },
    watch: {
        // 实现双向绑定
        visible(val) {
            if (val === false) {
                this.form.resetFields(); // 重置表单
            } else {
                this.getData();
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible'
    },
    methods: {
        closeModal() {
            this.$emit('changeVisible', false);
        },
        getData() {
        },
        okFun() {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/job/delay';
                        let data = values;

                        data.jid = this.jid;
                        //console.log(data);return;
                        data.etime = data.etime ? data.etime.format('YYYY-MM-DD') : ''

                        this.http(url, data, (res) => {
                            this.toast({content: '设置成功'});
                            this.closeModal();
                            this.$emit("confirm");
                        }, null, 1);
                    }
                }
            );
        },
        cancelFun() {
            this.closeModal();
        },
    }
}
</script>
