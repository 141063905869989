<template>
    <a-table
        :columns="columns"
        :dataSource="dataSource"
        :rowKey="rowKey"
        :loading="{spinning: loading}"
        :pagination="pagination"
        :rowSelection="rowSelection"
        :locale="localeRes"
        :scroll="scroll ? scroll : {}"
        @change="change"
    >
        <!-- 考试连接 -->
        <span slot="kaoshi_num" slot-scope="text, row, index">
            <a href="javascript:;" @click="actionTo('/enter/list?pid=' + row.pid)">{{ text }}</a>
        </span>
        <span slot="enter_status" slot-scope="text, row, index">
            <a href="javascript:;" @click="actionTo('/enter/list?pid=' + row.pid + '&status=0')">{{ text }}</a>
        </span>
        <span slot="enter_wrfee" slot-scope="text, row, index">
            <a href="javascript:;" @click="actionTo('/enter/list?pid=' + row.pid + '&wrfee=1')">{{ text }}</a>
        </span>
        <span slot="enter_instatus" slot-scope="text, row, index">
            <a href="javascript:;" @click="actionTo('/enter/list?pid=' + row.pid + '&instatus=1')">{{ text }}</a>
        </span>
        <span slot="enter_infee" slot-scope="text, row, index">
            <a href="javascript:;" @click="actionTo('/enter/list?pid=' + row.pid + '&infee=1')">{{ text }}</a>
        </span>
        <span slot="enter_result" slot-scope="text, row, index">
            <a href="javascript:;" @click="actionTo('/enter/list?pid=' + row.pid + '&result=1')">{{ text }}</a>
        </span>

        <!-- 报名管理 -->
        <span slot="enter_avatar" slot-scope="text, row, index" class="action-oper">
            <div style="width:60px;height: 60px;overflow: hidden;display: flex;justify-content:center;align-items:center;">
                <a :href="row.avatar_str" target="_blank" v-if="row.avatar_str"><img :src="row.avatar_str" width="60" /></a>
            </div>
        </span>
        <span slot="enter_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('chushen', row)" v-if="row.status == 0">初审</a>
            <a href="javascript:;" @click="actionFun('csclose', row)" v-if="row.status == 0">不通过</a>
            <a href="javascript:;" @click="actionFun('wrfee', row)" v-if="row.status == 1 && row.wrfee == 0">缴费</a>
            <a href="javascript:;" @click="actionFun('interview', row)" v-if="row.status == 1 && row.instatus == 0">面审</a>
            <a href="javascript:;" @click="actionFun('inclose', row)" v-if="row.status == 1 && row.instatus == 0">不通过</a>
            <a href="javascript:;" @click="actionFun('infee', row)" v-if="row.instatus == 1 && row.infee == 0">缴费</a>
            <a href="javascript:;" @click="actionFun('result', row)" v-if="row.instatus == 1 && row.result == 0">录取</a>
            <a href="javascript:;" @click="actionFun('reclose', row)" v-if="row.instatus == 1 && row.result == 0">未录取</a>
        </span>

        <!-- 公告管理 -->
        <span slot="knews_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 岗位 -->
        <span slot="job_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 项目 -->
        <span slot="project_pname" slot-scope="text, row, index"
              :title="text"
              style="display:block;width:160px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
        >
            {{ text }}
        </span>

        <span slot="kaoshi_job" slot-scope="text, row, index">
            <a href="javascript:;" @click="actionTo('/job/list?pid=' + row.pid)">{{ text }}</a>
        </span>
        <span slot="project_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('import', row)">导岗位</a>
            <a href="javascript:;" @click="actionFun('news', row)">发公告</a>
        </span>

        <!--广告列表-->
        <span slot="qiye_image" slot-scope="text, row, index" class="action-oper">
            <div style="width:60px;height: 60px;overflow: hidden;display: flex;justify-content:center;align-items:center;">
                <a :href="row.imageSrc" target="_blank" v-if="row.image" style="margin-left: 10px;"><img :src="row.imageUrl" width="60" /></a>
            </div>
        </span>
        <span slot="qiye_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 广告分类 -->
        <span slot="qiye_class_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!--广告列表-->
        <span slot="ad_image" slot-scope="text, row, index" class="action-oper">
            <div style="width:60px;height: 60px;overflow: hidden;display: flex;justify-content:center;align-items:center;">
                <a :href="row.imageSrc" target="_blank" v-if="row.image" style="margin-left: 10px;"><img :src="row.imageUrl" width="60" /></a>
            </div>
        </span>
        <span slot="ad_description" slot-scope="text, row, index"
              :title="text"
              style="display:block;width:300px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
        >
            {{ text }}
        </span>
        <span slot="ad_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 广告分类 -->
        <span slot="ad_class_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>


        <!--新闻列表-->
        <span slot="news_image" slot-scope="text, row, index" class="action-oper">
            <div style="width:60px;height: 60px;overflow: hidden;display: flex;justify-content:center;align-items:center;">
                <a :href="row.imageSrc" target="_blank" v-if="row.image" style="margin-left: 10px;"><img :src="row.imageUrl" width="60" /></a>
            </div>
        </span>
        <span slot="news_title" slot-scope="text, row, index"
            :title="text"
            style="display:block;width:260px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
        >
            <a-tag color="red" v-if="row.istop == 1">顶</a-tag>{{ text }}
        </span>
        <span slot="news_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('top', row)" v-if="row.istop == 0">置顶</a>
            <a href="javascript:;" @click="actionFun('untop', row)" v-else-if="row.istop == 1">取消置顶</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 新闻分类 -->
        <span slot="news_class_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!--主账户管理-->
        <span slot="act_action" slot-scope="text, row, index" class="action-oper">
            <a v-if="check_limit('account_edit')" href="javascript:void(0);" @click="actionFun('edit', row)">续费</a>
            <a v-if="check_limit('account_open') && row.status == 9" href="javascript:void(0);" @click="actionFun('open', row)">开启</a>
            <a v-if="check_limit('account_close') && row.status == 1" href="javascript:void(0);" @click="actionFun('close', row)">关闭</a>
        </span>

        <!--用户列表-->
        <span slot="account_action" slot-scope="text, row, index" class="action-oper">
            <a v-if="check_limit('system_user_info')" href="javascript:void(0);" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:void(0);" @click="actionFun('close', row)" v-if="check_limit('system_close_user') && row.status == 1">关闭</a>
            <a v-else-if="check_limit('system_open_user')" href="javascript:void(0);" @click="actionFun('open', row)">开启</a>
        </span>

        <!--角色列表-->
        <span slot="role_action" slot-scope="text, row, index" class="action-oper">
            <a v-if="check_limit('system_role_info')" href="javascript:void(0);" @click="actionFun('edit', row)">编辑</a>
            <a v-if="check_limit('system_delete_role')" href="javascript:void(0);" @click="actionFun('delete', row)">删除</a>
        </span>


    </a-table>
</template>

<script>
    export default {
        props: {
            // 字段
            columns: {
                type: Array,
                default: [],
            },
            // 数据
            dataSource: {
                type: [Object, Array],
                default: null,
            },
            // loading加载
            loading: {
                type: Boolean,
                default: false,
            },
            // 表格行 key 的取值
            rowKey: {
                type: String,
                default: '',
            },
            // 页码{ pageSize: pageSize, total: total, current: current, }
            pagination: {
                type: [Boolean, Object],
                default: false,
            },
            // 列表项是否可选择
            rowSelection: {
                type: [Object],
                default: null,
            },
            // 默认文案设置
            locale: {
                type: Object,
                default: null,
            },
            // 滚动条设置
            scroll: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                // indicator: <i class="anticon anticon-loading anticon-spin ant-spin-dot" style="font-size: 24px;"></i>,
                localeRes: {
                    emptyText: '暂无数据'
                },
            }
        },
        methods: {
            // 分页回调
            change(e) {
                this.$emit('page', e);
            },
            actionFun(op, row) {
                row.op = op;
                this.$emit('actionFun', row);
            },
            actionTo(url) {
                this.$router.push(url);
            }
        }
    }
</script>

<style lang="less">
    .action-oper{
        a{
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
</style>
