<template>
  <a-modal
    title="创建分类"
    :visible="visible"
    class="modal-initialize"
    width="508px"
    @cancel="cancelFun"
    @ok="okFun"
  >
    <div class="bomb-remind">
      <div class="bomb-form">
        <a-form class="inline-form" :form="form">

          <div class="form-title">
            <h4>分类名称</h4>
          </div>
          <a-form-item>
            <a-input placeholder="请填写分类名称" v-decorator="['qcname', {rules: [{ required: true,  message: '请填写分类名称' }]} ]"/>
          </a-form-item>

          <div class="form-title">
            <h4>调取标识</h4>
          </div>
          <a-form-item>
            <a-input placeholder="请填写调取标识" v-decorator="['flag', {rules: [{ required: true, message: '请填写调取标识' }]} ]"/>
          </a-form-item>

        </a-form>
      </div>
    </div>
  </a-modal>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            qcid: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 5
                    },
                    wrapperCol: {
                        span: 19
                    },
                },
            };
        },
        watch: {
            // 实现双向绑定
            visible(val) {
                if (val === false) {
                    this.form.resetFields(); // 重置表单
                } else {
                    this.getData();
                }
            },
        },
        model: {
            prop: 'visible',
            // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
            event: 'changeVisible'
        },
        methods: {
            closeModal() {
                this.$emit('changeVisible', false);
            },
            getData() {
                if (this.qcid) {
                    // 初始化底部宽度
                    this.$nextTick(() => {
                        let data = {};
                        data.qcid = this.qcid;

                        this.http('/manage/qiyead/class-info', data, (res) => {
                            this.form.setFieldsValue({
                                qcname: res.data.qcname,
                                flag: res.data.flag,
                            });
                        }, null, 1);

                    });
                }
            },
            okFun() {
                this.form.validateFields(
                    (errors, values) => {
                        if (!errors) {

                            let url = '';
                            let data = values;

                            if (this.qcid) {
                                data.qcid = this.qcid;
                                url = '/manage/qiyead/class-edit';
                            } else {
                                url = '/manage/qiyead/class-save';
                            }
                            //console.log(data);return;

                            this.http(url, data, (res) => {
                                this.toast({content: '设置成功'});
                                this.closeModal();
                                this.$emit("confirm");
                            }, null, 1);
                        }
                    }
                );
            },
            cancelFun() {
                this.closeModal();
            },
        }
    }
</script>
