<template>
    <div class="wrap">
        <listHeader :title="userid > 0 ? '编辑员工' : '新增员工'"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-column-title flex align-items"><span class="line"></span>
                    <h3>基本设置</h3></div>
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">
                                <a-form-item
                                    label="用户名"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入用户名" :readonly="userid > 0" :disabled="userid > 0"
                                             v-decorator="['username', {rules: [
                                        { required: true, message: '请输入用户名' },
                                        { pattern: /^[a-z0-9]{4,16}$/, message: '用户名[4-16位、小写字母和数字组成]' },
                                    ], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="姓名"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入姓名"
                                             v-decorator="['name', {rules: [{ required: true, min: 2, max: 6, message: '请输入姓名（2-6位）' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="密码"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input type="password" placeholder="请输入密码"
                                             v-decorator="['passwd', {rules: [{ validator: changePasswd, message: '登录密码为[8-16位、字母开头、不全为字母]' }] }]"/>
                                    <div v-if="userid" class="duty-no-remind">默认密码：{{ defaul_passwd }}</div>
                                </a-form-item>

                                <a-form-item
                                    label="手机号"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入手机号"
                                             v-decorator="['mobile', {rules: [{ required: true, len: 11, message: '请输入11位手机号' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="角色"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-row :gutter="24">
                                        <a-col :span="14">
                                            <a-select
                                                placeholder="请选择角色"
                                                v-decorator="['roleid',{rules: [{ required: true, message: '请选择角色' }]}]"
                                            >
                                                <a-select-option v-for="item in rolelist" :key="item.roleid"
                                                                 :value="item.roleid">
                                                    {{ item.rolename }}
                                                </a-select-option>
                                            </a-select>
                                        </a-col>

                                        <a-col :span="10">
                                            <div class="refresh flex align-items">
                                                <a href="javascript:void(0);" @click="getData()"
                                                   class="flex align-items">
                                                    <i class="refresh-icon"></i>刷新
                                                </a>
                                                <a href="/role/create" target="_blank" class="flex align-items main">
                                                    <i class="iconfont iconadd"></i>新增角色
                                                </a>
                                            </div>
                                        </a-col>
                                    </a-row>

                                </a-form-item>

                                <a-form-item
                                    label="状态"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-radio-group
                                        v-decorator="['status', {rules: [{ required: true, message: '请选择状态' }], initialValue: ''}]"
                                        :readonly="userid > 0" :disabled="userid > 0">
                                        <a-radio v-for="(item, key) in statuses" :key="key" :value="key">{{
                                                item
                                            }}
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-item>

                                <a-form-item
                                    v-bind="formBtnLayout"
                                >
                                    <a-button type="primary" @click="create">确定</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader';

export default {
    components: {
        listHeader
    },
    data() {
        return {
            formItemLayout: {
                labelCol: {
                    span: 5
                },
                wrapperCol: {
                    span: 19
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            defaul_passwd: '',
            rolelist: [],// 角色列表
            statuses: {},// 状态
            userid: 0,
        }
    },
    created() {
        if (this.$route.query.userid !== undefined) {
            this.userid = this.$route.query.userid;
        }
        this.getData();
    },
    methods: {
        getData() {
            this.http('/manage/system/user-info', {userid: this.userid}, (res) => {
                this.rolelist = res.rolelist;
                this.statuses = res.config.statuses;
                this.defaul_passwd = res.config.passwd;
                if (this.userid > 0) {
                    this.form.setFieldsValue({
                        username: res.userinfo.username,
                        name: res.userinfo.name,
                        mobile: res.userinfo.mobile,
                        roleid: res.userinfo.roleid,
                        status: res.userinfo.status,
                    });
                } else {
                    // 默认角色
                    for (let i in this.rolelist) {
                        this.form.setFieldsValue({roleid: this.rolelist[i].roleid});
                        break;
                    }
                    // 默认类型
                    for (let i in this.statuses) {
                        this.form.setFieldsValue({status: i});
                        break;
                    }
                }
            }, null, 2);
        },
        // 自定义验证密码
        changePasswd(rule, value, callback) {
            let validate = /^[a-zA-Z](?![a-zA-Z]+$).{7,15}$/.test(value);
            if (value && validate === false) {
                callback(true);
            } else {
                callback();
            }
        },
        create() {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {
                        let url = '';
                        if (this.userid > 0) {
                            values.userid = this.userid;
                            url = '/manage/system/edit-user';
                        } else {
                            url = '/manage/system/save-user';
                        }

                        this.http(url, values, (res) => {
                            this.toast({content: '操作成功'});
                            this.$router.push('/account/list');
                        }, null, 1);
                    }
                }
            );
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
