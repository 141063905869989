import layout from '@/components/common/layout';
import enterList from '../views/enter/list';

export default [
    {
        path: '/enter',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "报名管理",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '137',// 父选项
                        child: 'enter_list',// 子选项
                    }
                },
                component: enterList,
            },
        ]
    },
];
