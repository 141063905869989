<template>
    <div class="wrap">
        <listHeader title="角色管理" scenario="roleList" @optionFun="headerOptionFun"></listHeader>

        <div class="wrap-list-content">
            <!--<search-screen :checkData="postData"></search-screen>-->

            <div class="hro-table hro-table-only">
                <hro-table
                    :columns="columns"
                    :dataSource="data"
                    :loading="loading"
                    :rowKey="'roleid'"
                    @actionFun="actionFun"
                >

                </hro-table>
            </div>
        </div>

        <list-bottom
            :page="postData.page"
            :pagesize="postData.pagesize"
            :total="total"
            scenario="roleList"
            @page="pageTo"
        >
        </list-bottom>
    </div>
</template>

<script>
import $ from 'jquery';
import searchScreen from '@/components/list/searchScreen';
import listBottom from '@/components/list/listBottom';
import hroTable from '@/components/list/table';
import listHeader from '@/components/list/listHeader';

export default {
    components: {
        listBottom,
        hroTable,
        searchScreen,
        listHeader,
    },
    data() {
        return {
            columns: [
                {
                    title: '名称',
                    dataIndex: 'rolename',
                },
                {
                    title: '状态',
                    dataIndex: 'status_str',
                },
                {
                    title: '最近更新时间',
                    dataIndex: 'updatetime_str',
                },
                {
                    title: '操作',
                    dataIndex: 'role_action',
                    scopedSlots: {customRender: 'role_action'},
                }
            ],
            loading: false,// 请求
            data: [],
            postData: {
                page: 1,
                pagesize: 10,
            },
            total: 0,
            scenario: 'roleList',
        }
    },
    watch: {
        '$store.state.showLayoutMenu'(val) {
            $('.list-bottom').width($('.wrap-list-content').width());
        },
    },
    created() {
        // 当浏览器大小变化时
        this.listenListWindow();

        // 请求数据
        this.setData();
        this.getData();
    },
    methods: {
        getData() {
            // 初始化选中
            this.selectedRowKeys = [];

            // 加载等待
            this.loading = true;
            // 路由变化
            // this.changeRouter();
            this.http('/manage/system/role-list', this.postData, (res) => {
                this.loading = false;
                // 数据渲染
                this.data = res.rolelist.data;
                this.total = parseInt(res.rolelist.count);

                // 初始化底部宽度
                this.$nextTick(() => {
                    $('.list-bottom').width($('.wrap-list-content').width());
                });
            }, null, 2);
        },
        // 设置参数
        setData() {
            for (let i in this.postData) {
                if (this.$route.query[i] !== undefined) {
                    this.postData[i] = this.$route.query[i];
                }
            }
        },
        // 路由监听
        changeRouter() {
            this.$router.push({path: '', query: this.postData});
        },
        // 翻页
        pageTo(e) {
            // 翻页
            this.postData.page = e;
            this.getData();
        },
        // 头部操作
        headerOptionFun(e) {
            if (e.op === 'create') {
                this.createTo();
            }
        },
        createTo() {
            this.$router.push('/role/create');
        },
        actionFun(e) {
            switch (e.op) {
                case 'edit':// 编辑
                    this.$router.push('/role/edit?roleid=' + e.roleid);
                    break;
                case 'delete':// 删除
                    this.confirm({title: '删除角色', content: `确认删除${e.rolename}？`}, () => {
                        this.http('/manage/system/delete-role', {roleid: e.roleid}, (res) => {
                            this.toast({content: '操作成功'});
                            this.getData();
                        }, null, 1);
                    });
                    break;
                default:
                    break;
            }
        },
    },
}
</script>


<style lang="less" src="../../assets/css/list/list.less"></style>
