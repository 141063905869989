<template>
    <div class="wrap">
        <listHeader title="用户管理" :scenario="scenario" @optionFun="headerOptionFun"></listHeader>

        <div class="wrap-list-content">

            <search-screen
                :checkData="postData"
                :set-condition="{
              pagesize: setCondition.pagesizes,
              field: setCondition.fields,
          }"
                :config="config"
                :scenario="scenario"
                @exportRes="exportRes(0)"
                @setOption="setOption"
                @clear="clear"
                @search="search"
            >
            </search-screen>

            <div class="hro-table">
                <hro-table
                    :columns="columns"
                    :dataSource="data"
                    :loading="loading"
                    :rowKey="'muid'"
                    @actionFun="actionFun"
                >

                </hro-table>
            </div>
        </div>

        <list-bottom
            :page="postData.page"
            :pagesize="postData.pagesize"
            :total="total"
            :scenario="scenario"
            @page="pageTo"
        >
        </list-bottom>
    </div>
</template>

<script>
import $ from 'jquery';
import searchScreen from '@/components/list/searchScreen';
import listBottom from '@/components/list/listBottom';
import hroTable from '@/components/list/table';
import listHeader from '@/components/list/listHeader';

export default {
    components: {
        listBottom,
        hroTable,
        searchScreen,
        listHeader,
    },
    data() {
        return {
            columns: [
                {
                    title: '用户名',
                    dataIndex: 'username',
                },
                {
                    title: '状态',
                    dataIndex: 'status_str',
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                },
                {
                    title: '手机号',
                    dataIndex: 'mobile',
                },
                {
                    title: '角色',
                    dataIndex: 'rolename',
                },
                {
                    title: '最后登录时间',
                    dataIndex: 'logintime_str',
                },
                {
                    title: '创建时间',
                    dataIndex: 'addtime_str',
                },
                {
                    title: '操作',
                    dataIndex: 'account_action',
                    scopedSlots: {customRender: 'account_action'},
                }
            ],
            scenario: 'accountList',
            loading: false,// 请求
            data: [],
            setCondition: {
                fields: [],// 显示字段
                pagesizes: {},// 每页显示数筛选条件
            },
            postData: {
                roleid: '',
                status: '',
                keyword: '',
                page: 1,
                pagesize: 10,
            },
            total: 0,
            config: {},
        }
    },
    watch: {
        '$store.state.showLayoutMenu'(val) {
            $('.list-bottom').width($('.wrap-list-content').width());
        },
    },
    created() {
        // 当浏览器大小变化时
        this.listenListWindow();

        // 请求数据
        this.setData();
        this.getData();
    },
    methods: {
        getData() {
            // 初始化选中
            this.selectedRowKeys = [];

            // 加载等待
            this.loading = true;
            // 路由变化
            // this.changeRouter();
            this.http('/manage/system/user-list', this.postData, (res) => {
                this.loading = false;
                // 数据渲染
                this.data = res.userlist.data;
                this.total = parseInt(res.userlist.count);
                this.config = res.config;
                this.config.rolelist = res.rolelist;

                // 初始化底部宽度
                this.$nextTick(() => {
                    $('.list-bottom').width($('.wrap-list-content').width());
                });
            }, null, 2);
        },
        // 设置参数
        setData() {
            for (let i in this.postData) {
                if (this.$route.query[i] !== undefined) {
                    this.postData[i] = this.$route.query[i];
                }
            }
        },
        // 路由监听
        changeRouter() {
            this.$router.push({path: '', query: this.postData});
        },
        // 翻页
        pageTo(e) {
            // 翻页
            this.postData.page = e;
            this.getData();
        },
        // 重置
        clear() {
            window.location.href = this.$route.path;
        },
        // 搜索
        search(param) {
            param.page = 1;
            param.pagesize = this.postData.pagesize;
            this.postData = param;
            this.getData();
        },
        // 头部操作
        headerOptionFun(e) {
            if (e.op === 'create') {
                this.createTo();
            }
        },
        createTo() {
            this.$router.push('/account/create');
        },
        actionFun(e) {
            switch (e.op) {
                case 'edit':// 编辑
                    this.$router.push('/account/edit?userid=' + e.muid);
                    break;
                case 'close':// 关闭
                    this.confirm({title: '关闭用户', content: `确认关闭${e.username}用户？`}, () => {
                        this.http('/manage/system/close-user', {userid: e.muid}, (res) => {
                            this.toast({content: '操作成功'});
                            this.getData();
                        }, null, 1);
                    });
                    break;
                case 'open':// 开启
                    this.http('/manage/system/open-user', {userid: e.muid}, (res) => {
                        this.toast({content: '操作成功'});
                        this.getData();
                    }, null, 1);
                    break;
                default:
                    break;
            }
        },
    },
}
</script>


<style lang="less" src="../../assets/css/list/list.less"></style>
