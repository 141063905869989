import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// 报名
import enter from './enter';
// 职位
import knews from './knews';
// 职位
import job from './job';
// 项目
import project from './project';
// 新闻
import news from './news';
// 广告
import ad from './ad';
// 企业广告位
import qiyead from './qiyead';
// 用户
import manager from './manager';
// 个人中心
import center from './center';
// 错误
import error from './error';
// 系统设置
import system from "./system";

export default new Router({
    mode: 'history',
    routes: [
        ...enter,
        ...knews,
        ...job,
        ...project,
        ...news,
        ...ad,
        ...qiyead,
        ...manager,
        ...center,
        ...error,
        ...system,
    ],
});
