<template>
    <div class="wrap">
        <listHeader :title="postData.nid > 0 ? '编辑新闻' : '发布新闻'"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="20">
                            <a-form :form="form">

                                <a-form-item
                                    label="公告类型"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-radio-group
                                        v-decorator="['type', {rules: [{ required: true, message: '请选择公告类型' }], initialValue: ''}]">
                                        <a-radio v-for="(item, key) in config.type" :key="key" :value="key">
                                            {{ item }}
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-item>

                                <a-form-item
                                    label="标题"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入新闻标题"
                                             v-decorator="['title', {rules: [{ required: true, max: 64, message: '请输入新闻标题' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="内容"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <div style="border: 1px solid #ccc;">
                                        <Toolbar
                                            style="border-bottom: 1px solid #ccc"
                                            :editor="editor"
                                            :defaultConfig="toolbarConfig"
                                            :mode="mode"
                                        />
                                        <Editor
                                            style="height: 300px; overflow-y: hidden;"
                                            v-model="html"
                                            :defaultConfig="editorConfig"
                                            :mode="mode"
                                            @onCreated="onCreated"
                                        />
                                    </div>
                                </a-form-item>

                                <a-form-item
                                    v-bind="formBtnLayout"
                                >
                                    <a-button type="primary" @click="create">确定发布</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader'
//import {Boot} from '@wangeditor/editor'
//import attachmentModule from '@wangeditor/plugin-upload-attachment'
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

//Boot.registerModule(attachmentModule)

export default {
    components: {
        listHeader,
        Editor, Toolbar
    },
    data() {
        return {
            visible: false, // 是否显示图片src
            loading: false, // 显示图片上传中
            formItemLayout: {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 20,
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                pid: 0,
                nid: 0,
            },
            data: {},
            config: {},
            dateFormat: 'YYYY-MM-DD',
            imageList: [],

            editor: null,
            html: '',
            toolbarConfig: {
                insertKeys: {
                    index: 23, // 自定义插入的位置
                    keys: ['uploadAttachment'], // “上传附件”菜单
                },
            },
            editorConfig: {
                hoverbarKeys: {
                    attachment: {
                        menuKeys: ['downloadAttachment'], // “下载附件”菜单
                    },
                },
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        customUpload: this.uploadImage
                    },
                    uploadVideo: {
                        customUpload: this.uploadVideo
                    },
                    uploadAttachment: {
                        customUpload: this.uploadFile
                    },
                }
            },
            mode: 'default', // or 'simple'
        }
    },
    Watch: {},
    created() {
        this.setData()
        this.getData()
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },

        setData() {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue
                }
                this.postData[i] = this.$route.query[i]
            }
        },
        getData() {
            // 查询数据
            this.http('/manage/knews/info', this.postData, (res) => {
                this.data = res.data
                this.config = res.config

                if (res.data.nid) {
                    this.visible = true
                    this.html = res.data.content;

                    this.form.setFieldsValue({
                        type: res.data.type,
                        title: res.data.title,
                    })
                } else {
                    // 默认类型
                    for (let i in res.config.type) {
                        this.form.setFieldsValue({type: i})
                        break
                    }
                }

            }, null, 2)
        },
        create() {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = ''
                        if (this.postData.nid > 0) {
                            values.nid = this.postData.nid
                            url = '/manage/knews/edit'
                        } else {
                            values.pid = this.postData.pid ? this.postData.pid : 0;
                            url = '/manage/knews/save'
                        }
                        values.content = this.html;
                        values.imageList = this.imageList

                        this.http(url, values, (res) => {
                            this.toast({content: '操作成功'})
                            this.$router.go(-1)
                        }, null, 1)
                    }
                },
            )
        },
        uploadImage(file, insertFn) {
            // console.log(file)

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(file);

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {

                let filedata = this.result.split(',')[1]

                let data = {
                    site: 'kaoshi',
                    module: 'news',
                    filestatus: 0,
                    filedata: {
                        'tmp_name': filedata,
                        'type': file.type,
                        'name': file.name,
                        'size': file.size,
                    },
                }

                that.httpImg('', data, (res) => {
                    that.imageList.push(res.data.file)
                    insertFn(res.data.url, res.data.filename, res.data.url)
                })
            }
        },
        uploadVideo(file, insertFn) {
            //console.log(file)

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(file);

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {

                let filedata = this.result.split(',')[1]

                let data = {
                    site: 'kaoshi',
                    module: 'news',
                    filestatus: 0,
                    filedata: {
                        'tmp_name': filedata,
                        'type': file.type,
                        'name': file.name,
                        'size': file.size,
                    },
                }

                that.httpImg('', data, (res) => {
                    that.imageList.push(res.data.file)
                    insertFn(res.data.url, res.data.filename, res.data.url)
                })
            }
        },
        uploadFile(file, insertFn) {
            //console.log(file)

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(file);

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {

                let filedata = this.result.split(',')[1]

                let data = {
                    site: 'kaoshi',
                    module: 'news',
                    filestatus: 0,
                    filedata: {
                        'tmp_name': filedata,
                        'type': file.type,
                        'name': file.name,
                        'size': file.size,
                    },
                }

                that.httpImg('', data, (res) => {
                    that.imageList.push(res.data.file)
                    insertFn(res.data.filename, res.data.url)
                })
            }
        },
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
